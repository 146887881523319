import React, { useState } from 'react'
import styled from 'styled-components'
import { Currency, Token } from '@goswap/sdk'
import { ALL_TOKENS } from '../../constants/tokens'

const EthereumLogo = 'https://raw.githubusercontent.com/goswap/cryptocurrency-icons/master/128/color/go.png'
//'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/gochain/info/logo.png'

// const getLogoByName = name =>
//    `https://raw.githubusercontent.com/goswap/cryptocurrency-icons/master/128/color/${name.toLowerCase()}.png`

// should maybe use this, but translate from ETH address -> GO address
const getLogoByAddress = address => {
  // check if there's an address on ethereum
  const t = ALL_TOKENS[6060][address]
  if (t != null) {
    address = t.network1 === 'ETH' ? t.address1 : t.address2
    if (address === '0x0000000000000000000000000000000000000000') {
      // ETH
      return 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png'
    }
    if (t.network1 === 'GO') {
      return `https://raw.githubusercontent.com/goswap/assets/master/blockchains/gochain/assets/${t.address1}/logo.png`
    } else {
      return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${t.address1}/logo.png`
    }
  }
  return `https://raw.githubusercontent.com/goswap/assets/master/blockchains/gochain/assets/${address}/logo.png`
}

const NO_LOGO_ADDRESSES: { [tokenAddress: string]: true } = {}

const Image = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const Emoji = styled.span<{ size?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ size }) => size};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin-bottom: -4px;
`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  ...rest
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const [, refresh] = useState<number>(0)

  if (currency instanceof Token) {
    let path = ''
    if (!NO_LOGO_ADDRESSES[currency.address]) {
      // path = getLogoByName(currency.symbol)
      path = getLogoByAddress(currency.address)
    } else {
      return (
        <Emoji {...rest} size={size}>
          <span role="img" aria-label="Thinking">
            🤔
          </span>
        </Emoji>
      )
    }

    return (
      <Image
        {...rest}
        alt={`${currency.name} Logo`}
        src={path}
        size={size}
        onError={() => {
          if (currency instanceof Token) {
            NO_LOGO_ADDRESSES[currency.address] = true
          }
          refresh(i => i + 1)
        }}
      />
    )
  } else {
    return <StyledEthereumLogo src={EthereumLogo} size={size} {...rest} />
  }
}
