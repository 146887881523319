// import { Token, ChainId } from '@goswap/sdk'

export default [
  // new Token(ChainId.GOCHAIN, '0x67bBB47f6942486184f08a671155FCFA6cAd8d71', 18, 'FAST', 'Fast.Finance'),
  // new Token(ChainId.GOCHAIN, '0xe8D71132Cd78146fbeD8c085f6c06CdeDF74E3Be', 18, 'ETH', 'Ether'),
  // new Token(ChainId.GOCHAIN, '0x97a19aD887262d7Eca45515814cdeF75AcC4f713', 6, 'USDC', 'USDC'),
  // new Token(ChainId.GOCHAIN, '0xF0d7C3ebF715c66Fd385B04D60de34993bf3Ca33', 6, 'USDT', 'USDT'),
  // new Token(ChainId.GOCHAIN, '0x46f1B92aaf1BBfEe2727F6B3beCdC6D78D2d37A1', 18, 'LINK', 'LINK'),
  // new Token(ChainId.GOCHAIN, '0xe996cbcb5595d949284F36314044f1158Cbc8273', 18, 'COMP', 'COMP'),
  // new Token(ChainId.GOCHAIN, '0x47b4F3C8Afe4E4c187FeD09861063A72D698Bbc6', 8, 'CRO', 'CRO'),
  // new Token(ChainId.GOCHAIN, '0x8758759249fB8f0af33fB386f8bb6714efB5Ba8f', 18, 'MKR', 'MKR'),
  // new Token(ChainId.GOCHAIN, '0x2017F46FCca0b60B2411A237f4053dF69729963E', 18, 'DAI', 'USDT'),
  // new Token(ChainId.GOCHAIN, '0x1C24c036E666c134FcC072768239A0E685e35Cb0', 18, 'YFI', 'yearn.finance'),
  // new Token(ChainId.GOCHAIN, '0x5aD9968cD8BEA45849413939BBBa5DE78B5350fB', 18, 'BAT', 'BAT'),
  // new Token(ChainId.GOCHAIN, '0x26BcDC943F7a0e4c61E1c038c028B847BEf84D81', 18, 'SXP', 'SXP'),
  // new Token(ChainId.GOCHAIN, '0x5B0972735BBA4A03963f3D38C123CA462895e2d1', 18, 'LEND', 'LEND'),
  // new Token(ChainId.GOCHAIN, '0x9Bf1A20b764b586f2F886cfa6365938617cfdAfD', 8, 'WBTC', 'WBTC'),
  // new Token(ChainId.GOCHAIN, '0xA25AB62fe6dC7e5BE35DF80Da3DA43ADD0a0e4A3', 18, 'TURBO', 'TURBO'),
]
