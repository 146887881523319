import { ChainId, Token } from '@goswap/sdk'
import React, { useState, Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ALL_TOKENS } from '../constants/tokens'
import { Spinner } from '../theme'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
// import Chainswap from './Chainswap'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 160px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 16px;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

// let tokensLoaded = false
export default function App() {
  // load tokens
  const [tokensLoaded, setTokensLoaded] = useState(false)
  fetch('https://stats-api.goswap.exchange/v1/tokens')
    .then(response => {
      return response.json()
    })
    .then(parsed => {
      // console.log('Response1', parsed)
      const tokes = []
      for (const t of parsed['tokens']) {
        tokes.push(new Token(ChainId.GOCHAIN, '0x67bBB47f6942486184f08a671155FCFA6cAd8d71', 18, 'FAST', 'Fast.Finance'))
        ALL_TOKENS[ChainId.GOCHAIN][t['address']] = new Token(
          ChainId.GOCHAIN,
          t['address'],
          t['decimals'],
          t['symbol'],
          t['name']
        )
      }
      setTokensLoaded(true)
    })

  const getChainswapTokens = async () => {
    try {
      const response = await fetch(`https://api.chainswap.org/v2/tokens`)

      const data = await response.json()

      if (data && data.tokens) {
        const tokeMap = {}
        for (const t of data.tokens) {
          if (t.network1 === 'GO') {
            tokeMap[t.address1] = t
          } else if (t.network2 === 'GO') {
            tokeMap[t.address2] = t
          }
        }
        ALL_TOKENS[6060] = tokeMap
      }
    } catch (e) {
      console.log(e)
    }
  }

  getChainswapTokens()

  if (!tokensLoaded) {
    return <Spinner></Spinner>
  }
  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <Web3ReactManager>
              <Switch>
                {/* <Route exact strict path="/chainswap" component={Chainswap} /> */}
                <Route exact strict path="/swap" component={Swap} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />
                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/migrate/v1" component={MigrateV1} />
                <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
                <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Web3ReactManager>
            <Marginer />
          </BodyWrapper>
          <div style={{ margin: 'auto' }}>
            <div style={{ fontSize: 14, borderRadius: 15, padding: 10, background: '#ff4040', color: 'white' }}>
              WARNING: Be careful if a token has this logo{' '}
              <span role="img" aria-label="Thinking">
                🤔
              </span>{' '}
              it may be a scam!
            </div>
          </div>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
