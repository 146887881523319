import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@goswap/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { network } from '../../connectors'
import { useEagerConnect, useInactiveListener } from '../../hooks'
import { NetworkContextName } from '../../constants'
import Loader from '../Loader'

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
`

const Message = styled.h2`
  color: ${({ theme }) => theme.secondary1};
`

let triedChainSwitch = false

async function addAndSwitchChains() {
  if (triedChainSwitch) {
    return
  }
  console.log('ADDING CHAIN')
  triedChainSwitch = true
  const { ethereum } = window
  if (!ethereum) {
    console.log('no ethereum')
    return
  }
  const chainIdInt = 60
  const chainId = `0x${chainIdInt.toString(16)}`
  console.log('hex chain id', chainId)
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }]
    })
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x3c',
              chainName: 'GoChain',
              nativeCurrency: {
                name: 'GO',
                symbol: 'GO',
                decimals: 18
              },
              rpcUrls: ['https://rpc.gochain.io'],
              blockExplorerUrls: ['https://explorer.gochain.io']
            }
          ]
        })
      } catch (addError) {
        // handle "add" error
        console.log('ADD CHAIN ERROR:', addError)
      }
    }
    // handle other "switch" errors
    console.log('SWITCH CHAIN ERROR:', switchError)
  }
}

export default function Web3ReactManager({ children }) {
  const { t } = useTranslation()
  const { active } = useWeb3React()
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName)

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network)
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager)

  addAndSwitchChains()

  // handle delayed loader state
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true)
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    return null
  }

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (!active && networkError) {
    return (
      <MessageWrapper>
        <Message>{t('unknownError')}</Message>
      </MessageWrapper>
    )
  }

  // if neither context is active, spin
  if (!active && !networkActive) {
    return showLoader ? (
      <MessageWrapper>
        <Loader />
      </MessageWrapper>
    ) : null
  }

  return children
}
